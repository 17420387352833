<script>
import Layout from "../../layouts/main.vue";

export default {
  name: "custom-maintenance-3",
  data() {
    return {
      title: "",
      items: [],
    };
  },
  components: {
    Layout,
  },
  mounted() {
    document.getElementById('content-main').addEventListener('click', this.redirectRouteHome)
  },
  methods: {
    redirectRouteHome() {
      window.location.href = "/"
    }
  }
};
</script>

<template>
  <Layout>
    <b-col lg="12 p-0 background">
    </b-col>
  </Layout>
</template>


<style lang="scss" scoped>

.page-content {
  padding: 0 !important;
}

.container-fluid {
  padding: 0 !important;
}

.background {
  background-image: url(/img/maqro_en_construccion_v2-min.png);
  background-size: cover;
  background-position: left;
  background-repeat: no-repeat;
  height: 100vh;
}



body {
  overflow-y: hidden !important;
  overflow-x: hidden !important;
}

</style>
